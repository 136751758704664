<template>
  <div>
    <main class="bg-gray-col">
      <section class="header-space"></section>
      <b-container fluid class="">
        <b-row class="mt-5 pt-5 align-items-center">
          <b-col class="offset-2 col-md-3"> <img src="@/assets/app-icons/download.svg" class="img-fluid" /></b-col>
          <b-col class="col-md-4">
            <h1 class="font-weight-bold">404</h1>
            <h4 class="mb-3 mt-3">Sorry but the page you are looking for doest not exist, have been removed, Name might have changed or Temporarly unavailable.</h4>
          </b-col>
          <!-- <b-col cols="10" md="8" class="bg-white rounded mt-5 mb-4 pt-5 pb-4">
            <b-row class="justify-content-center">
              <b-col cols="10" sm="8" lg="6">
                <div class="text-center mb-3"></div>
               
               
                <div class="text-center"></div>
              </b-col>
            </b-row>
          </b-col> -->
        </b-row>
      </b-container>
    </main>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {};
  },
};
</script>
<style>
</style>

